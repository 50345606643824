/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
//import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"
//import Helmet from "react-helmet"
//import { withPrefix, Link } from "gatsby"

//import Header from "./header"
//import "./layout.css"
//import 'bootstrap/dist/css/bootstrap.min.css';




//import Helmet from 'react-helmet'

// Inside your component


export default class Layout extends React.Component {
//const Layout = ({ children }) => {



render(){
  //var children = this.props.children;
  return (
    <>
        <main >{this.props.children}</main>
    </>
  )
}

}
